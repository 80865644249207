import React from 'react'
import { styled, Link, Stack, Typography } from '@mui/material'

const SalesContactWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '1rem',
}))

const Header = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: 14,
  fontWeight: 600,
}))

const Phone = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: 14,
  fontWeight: 600,
  margin: '10px 0',
}))

const Email = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: 12,
}))

const Hours = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: 12,
}))

const SalesContact = () => (
  <SalesContactWrapper sx={{ margin: { xs: '16px', md: '16px 0' } }}>
    <Header>Internet Sales Support Staff</Header>
    <Phone href="tel:1-888-709-5380" underline="hover">
      1-888-709-5380
    </Phone>
    <Email href="mailto:InternetSalesSupport@roomstogo.com" underline="hover">
      internetSalesSupport@roomstogo.com
    </Email>
    <Hours>Mon - Sat: 9AM - 9PM EST</Hours>
    <Hours>Sunday: 11AM - 7PM EST</Hours>
  </SalesContactWrapper>
)

export default SalesContact
