import React from 'react'
import { bool, func, string } from 'prop-types'
import { styled, Button, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ApplyButton from '@shared/apply-button'
import RTGLink from '@shared/link'

const EquickWrapper = styled(Stack, {
  shouldForwardProp: prop => !['isSidebar'].includes(prop),
})(({ isSidebar, theme }) => ({
  backgroundColor: 'rgba(0, 83, 160, 0.05)',
  padding: '16px',
  position: 'relative',
  marginTop: 0,
  width: '100%',
  [theme.breakpoints.up('md')]: isSidebar
    ? {}
    : {
        padding: '25px',
        width: '100vw',
        marginLeft: 'calc((-100vw + 100%) / 2)',
        marginRight: 'calc((-100vw + 100%) / 2)',
        marginTop: '-10px',
      },
}))

const EquickContent = styled(Stack)({
  width: '100%',
  maxWidth: '1616px',
  '& a': { width: '100%' },
  '& .equick-btn': {
    display: 'flex',
    color: 'white',
    height: '37px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '300px',
  },
})

const EquickHeader = styled(Typography)({
  color: '#07263B',
  fontWeight: 500,
  fontSize: '18px',
})

const ApprovedMessage = styled(Typography, {
  shouldForwardProp: prop => !['isSidebar'].includes(prop),
})(({ isSidebar, theme }) => ({
  color: '#07263B',
  '& span': {
    fontWeight: 600,
  },
  [theme.breakpoints.up('md')]: isSidebar ? {} : { paddingLeft: '32px' },
}))

const AcceptedMessage = styled(Typography, {
  shouldForwardProp: prop => !['isSidebar'].includes(prop),
})(({ isSidebar, theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: '18px',
  fontWeight: 500,
  paddingTop: isSidebar ? 0 : '16px',
  [theme.breakpoints.up('md')]: isSidebar ? {} : { paddingTop: 0, paddingLeft: '32px' },
}))

const DesktopCloseButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: { display: 'none' },
}))

const MobileCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  [theme.breakpoints.up('md')]: { display: 'none' },
}))

const EquickBanner = ({
  acceptedMessage = 'Congratulations! You are approved for the Rooms To Go credit card!',
  creditLimit = '',
  disableCheckout,
  expirationDate = 'xx/xx/xxxx',
  handleAccept,
  handleClose,
  isAccepted,
  isPending,
  isSidebar,
  sitecode = 'rgpcse1d1',
  synchronyEapplyUrl,
}) => (
  <EquickWrapper direction="row" alignItems="center" justifyContent="center" isSidebar={isSidebar}>
    {handleClose && (
      <MobileCloseButton aria-label="Close Rooms To Go credit card offer message" size="small" onClick={handleClose}>
        <CloseIcon htmlColor="#07263B" fontSize="inherit" />
      </MobileCloseButton>
    )}
    <EquickContent
      direction={{ xs: 'column', md: isSidebar ? 'column' : 'row' }}
      justifyContent="space-between"
      spacing={{ xs: 2, md: isSidebar ? 2 : 1 }}
    >
      <Stack spacing={{ xs: 1, md: isSidebar ? 1 : 0.5 }} justifyContent="center">
        {!isAccepted && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <CheckCircleIcon htmlColor="#07263B" />
            <EquickHeader variant="h5">You Are Pre-Approved!</EquickHeader>
          </Stack>
        )}
        {isAccepted ? (
          <AcceptedMessage isSidebar={isSidebar}>{acceptedMessage}</AcceptedMessage>
        ) : (
          <ApprovedMessage isSidebar={isSidebar}>
            You're pre-approved for the Rooms To Go credit card for a credit limit of no less than{` `}
            <span>${creditLimit}</span>.{isSidebar ? <br /> : ' '}Don't Wait! This offer expires{` `}
            on <span>{expirationDate}</span>.
          </ApprovedMessage>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={11}>
        {!isPending && (
          <>
            {isAccepted ? (
              <Button
                sx={{ minWidth: '300px', height: '37px', padding: '6px 0' }}
                variant="contained"
                fullWidth
                disabled={disableCheckout}
              >
                <RTGLink
                  data={{
                    slug: '/checkout',
                    category: 'cart',
                    action: 'checkout',
                    label: 'checkout',
                  }}
                  disabled={disableCheckout}
                >
                  <span className="equick-btn">Checkout now</span>
                </RTGLink>
              </Button>
            ) : (
              <Button sx={{ minWidth: '300px', height: '37px' }} variant="contained" fullWidth>
                <ApplyButton
                  asButton
                  url={`${synchronyEapplyUrl}&sitecode=${sitecode}`}
                  category="credit-card"
                  action="apply-now"
                  label="cart"
                  title="Rooms To Go Credit Options"
                  handleClose={handleAccept}
                  // href={synchronyEapplyUrl}
                  // asModal={false}
                >
                  <span className="equick-btn">Accept Offer</span>
                </ApplyButton>
              </Button>
            )}
          </>
        )}
        {handleClose && (
          <DesktopCloseButton
            aria-label="Close Rooms To Go credit card offer message"
            size="large"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </DesktopCloseButton>
        )}
      </Stack>
    </EquickContent>
  </EquickWrapper>
)

EquickBanner.propTypes = {
  acceptedMessage: string,
  creditLimit: string,
  disableCheckout: bool,
  expirationDate: string,
  handleAccept: func,
  handleClose: func,
  isAccepted: bool,
  isPending: bool,
  isSidebar: bool,
  sitecode: string,
  synchronyEapplyUrl: string,
}

export default EquickBanner
