/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { styled, CircularProgress, Grid } from '@mui/material'
import { useFeatureValue, useFeatureIsOn } from '@growthbook/growthbook-react'
import { GROWTHBOOK_PROMO_FINANCING, GROWTHBOOK_VARIANTS } from '@constants/growthbook-flags'
import { removeUnavailableItems, addToCart, compareLineItems } from '@helpers/cart'
import { clearCheckoutState, getLineItems } from '@helpers/checkout/global'
import { setEquickDecision } from '@helpers/checkout/payment-section/rtg-finance'
import { reloadStoreCart } from '@helpers/checkout/shipping-section'
import { productFinancing } from '@helpers/finance'
import { getCurrentLocation, getRegionZone } from '@helpers/geo-location'
import { addToDataLayer_nextgen, removeStoreCartEvent } from '@helpers/google-tag-manager'
import { getTotalDisplayValue } from '@helpers/order'
import { availabilityStockMessage } from '@helpers/product'
import { sentryLogger, setExtra, levels } from '@helpers/sentry-logger'
import { clearCart } from '@redux/modules/cart'
import { setOrder } from '@redux/modules/checkout'
import { checkSplitElig, updateLineItems } from '@services/checkout'
import CondensedContact from '@shared/condensed-contact-links'
import PromotionContentGroup from '@shared/promotion-content-group'
import ViewMyStoreCartModal from '@shared/modals/view-my-store-cart-modal'
import { CartAndCheckoutSpinnerWrapper, LoadingMessage } from '@shared/LoadingSpinner/CartAndCheckoutSpinner'
import { PRODUCT_CAROUSEL } from '../../@rtg2022/index'
import { SplitDeliveryText, SplitDeliveryTextWrapper } from './cart-parts/styles'
import CheckoutSticky from '../checkout/checkout-sticky/checkout-sticky'
import CartCreditCardBanner from './cart-parts/CartCreditCardBanner'
import CartEmpty from './cart-parts/cart-empty'
import CartProduct from './cart-parts/cart-product'
import LargeHeader from './cart-parts/large-header'
import PaymentMethods from './cart-parts/payment-methods'
import SalesContact from './cart-parts/sales-contact'
import EquickBanner from './equickBanner'
import StoreCart from './store-cart'
import StoreCartParagraph from './store-cart/store-cart-paragraph'

import '@comp-sass/cart/cart.sass'

const FullWidthDiv = styled('div')({
  width: '100%',
})

const MobileCartCreditCardBannerWrapper = styled('div')({
  width: '100vw',
  marginLeft: '-0.9375rem',
  marginRight: '-0.9375rem',
  marginBottom: 15,
})

const userAcknowledgedToCombineStoreAndOnlineCarts = 'userAcknowledgedToCombineStoreAndOnlineCarts'
const splitCheckVerbiage =
  'Some items may be available sooner than others. You may be able to split this delivery at checkout.'

const AllCartsWrapper = ({
  cart,
  order,
  discount,
  isMobile,
  promoTargetSkus,
  rtg_location,
  setSkusNotAvailable,
  showPayPal,
  skusNotAvailable,
  storeCartIsLoading,
  setStoreCartIsLoading,
}) => {
  const dispatch = useDispatch()
  const [splitDeliveryElig, setSplitDeliveryElig] = useState(false)
  const [refreshComponent, setRefreshComponent] = useState(false)
  const [shouldShowFinanceText, setShouldShowFinanceText] = useState(false)
  const [totalFinanceAmount, setTotalFinanceAmount] = useState(0)
  const [financeNumOfMonths, setFinanceNumOfMonths] = useState(0)
  const [carouselSlides, setCarouselSlides] = useState(null)
  const promoFinancing = useFeatureValue(GROWTHBOOK_PROMO_FINANCING)
  const isShowFinanceMessageFeatureOn = useFeatureIsOn(GROWTHBOOK_VARIANTS.cart?.showFinance)
  const { region: myRegion } = getRegionZone() // user's region determines YMAL products

  const [showEquickBanner, setShowEquickBanner] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)
  const { isPrequalified = false, quickScreenInfo = {} } = order?.financePlan ?? {}
  const isQuickScreenPending = quickScreenInfo?.decision === 'CREDIT_PENDING'

  const productsAvailable = skusNotAvailable.length < 1

  const handleAccept = useCallback(async () => {
    /* This function is called when the user closes the Sychrony modal that opens after clicking the Accept Offer button in equickBanner.
        Handle these values of decision in the API response:
        1) "APPROVED" - user closes modal without completing application (nothing has changed, so continue showing pre-approval offer),
        2) "CREDIT_APPROVED" - user completes app and is approved (change status to approved and display congrats msg & checkout btn),
        3) "CREDIT_PENDING" - user completes app and approval is pending (display pending approval msg and hide CTA btn), or
        4) "CREDIT_DECLINED" - user completes app but is declined credit (clear QS info).
    */
    try {
      setShowEquickBanner(false)
      setStoreCartIsLoading(true)
      await reloadStoreCart(true)
    } catch {
      setEquickDecision()
      console.error('Error updating Store Cart')
    } finally {
      setStoreCartIsLoading(false)
    }
  }, [setStoreCartIsLoading])

  const eQuickProps = useMemo(
    () => ({
      acceptedMessage: isQuickScreenPending
        ? 'Your Rooms To Go credit card application has been submitted and is currently pending approval.'
        : undefined,
      creditLimit:
        quickScreenInfo?.creditLimit || quickScreenInfo?.creditLimit === 0 ? `${quickScreenInfo?.creditLimit}` : '',
      disableCheckout: !productsAvailable,
      expirationDate: quickScreenInfo?.offerExpiryDate ?? '',
      handleAccept,
      isAccepted,
      isPending: isQuickScreenPending,
      sitecode: isMobile ? 'rgpcsl1d1' : 'rgpcse1d1',
      synchronyEapplyUrl: quickScreenInfo?.synchronyEapplyUrl ?? '',
    }),
    [handleAccept, isAccepted, isMobile, quickScreenInfo],
  )

  const storeCartImages = {}
  if (order?.storeCart?.lineItems) {
    order?.storeCart?.lineItems?.forEach(item => {
      storeCartImages[item.sku] = item?.imageUrl ?? null
    })
  }

  const storeCartLineItems = useMemo(
    () => (order && order.lineItems ? order.lineItems.filter(lineItem => lineItem.isStoreSku) : []),
    [order.lineItems],
  )
  const storeCartHasProducts = storeCartLineItems.length > 0
  const onlineCartHasProducts = cart && cart?.cartItems && cart?.cartItems?.length > 0
  const showCombineCartsModal =
    storeCartHasProducts &&
    onlineCartHasProducts &&
    !sessionStorage.getItem(userAcknowledgedToCombineStoreAndOnlineCarts)

  const query = useMemo(
    () =>
      order?.lineItems
        ?.filter(item => item?.deliveryType !== 'T')
        ?.map(item => {
          if (item?.isConfigurable) {
            const childSkus = item?.childItems.map(i => i.sku).join(',') ?? ''
            return childSkus
          }
          return item?.sku
        })
        ?.join(','),
    [order],
  )

  const lineItems = getLineItems()
  const cartQuantityHasChanged = !compareLineItems(lineItems, order?.lineItems ?? [])
  // Prevent store cart loading from appearing when cart only has online products
  const showStoreCartLoading = storeCartIsLoading && !cartQuantityHasChanged

  useEffect(() => {
    setShowEquickBanner(isPrequalified)
    if (quickScreenInfo?.decision === 'CREDIT_APPROVED' || isQuickScreenPending) setIsAccepted(true)
  }, [isPrequalified, quickScreenInfo])

  useEffect(() => {
    // populate YMAL carouselSlides based on SKUs included in order.lineItems and user's current region
    if (query) {
      const options = {
        query,
        categories: false,
        properties: false,
      }
      /**
       * AWS Secrets Variables
       * GATSBY_RECOMMENDATION_SERVICE: https://recommendation.rtg-dev.com (DEV), https://recommendation.rtg-prod.com (PROD)
       *
       * Categories - Defaults to all categories
       * @example https://recommendation.rtg-dev.com/youMayAlsoLike/10341737,1223550P,99035416,1014185P,1004185P?categories=accessories,lamps,lighting
       *
       * Properties - Defaults to all properties
       * @includes  sku,title,description,category,sub_category,pricing,free_shipping,catalog_availability,delivery_type,sell_individually,hasVariations,primary_image,alternate_images
       * @example https://recommendation.rtg-dev.com/youMayAlsoLike/10341737,1223550P,99035416,1014185P,1004185P?properties=sku,title,description
       *
       * Division - Set to whatever region the user is in
       * @example https://recommendation.rtg-dev.com/youMayAlsoLike/10341737,1223550P,99035416,1014185P,1004185P?division=FL
       */
      const URL = `${process.env.GATSBY_RECOMMENDATION_SERVICE}/youMayAlsoLike/${options.query}?division=${myRegion}`
      // const filterByCategory = `?categories=${options.categories}`
      // const filterByProperties = `?properties=${options.properties}`
      // const URL = base_url
      fetch(URL)
        .then(response => response.json())
        .then(json => setCarouselSlides(json))
        .catch(err => setCarouselSlides(null))
    }
  }, [query, myRegion])

  useEffect(() => {
    let showFinanceText = false

    const total = getTotalDisplayValue(order, false, {}, cart, storeCartLineItems)
    const { showFinance, financeAmount, financeNumMonths } = productFinancing(
      total,
      null,
      true,
      false,
      0,
      promoFinancing,
    )
    if (showFinance) showFinanceText = true
    setTotalFinanceAmount(financeAmount)
    setFinanceNumOfMonths(financeNumMonths)
    setShouldShowFinanceText(showFinanceText)
  }, [JSON.stringify(cart.cartItems), order.total, promoFinancing])

  useEffect(() => {
    let fetching = false

    async function fetchSplitCheckElig() {
      const result = await checkSplitElig(order.orderId, rtg_location.zip)
      if (!fetching) setSplitDeliveryElig(result)
    }
    try {
      if (order.orderId && rtg_location.zip) {
        fetchSplitCheckElig()
      }
    } catch (error) {
      sentryLogger({
        configureScope: {
          type: setExtra,
          message: `cart - fetching split check eligibility: ${error}`,
          level: levels.error,
        },
      })
    }
    return () => {
      fetching = true
    }
  }, [JSON.stringify(order.lineItems), order.orderId, rtg_location.zip])

  useEffect(() => {
    if (cart?.cartItems.length === 0 && storeCartHasProducts) {
      sessionStorage.removeItem(userAcknowledgedToCombineStoreAndOnlineCarts)
    }
  }, [cart])

  // remove store cart packages from the store cart
  const removeStoreCartProduct = async storeCartItemToRemove => {
    if (storeCartHasProducts) {
      const { isUnavailable, sku: skuToRemove, unitPrice, quantity } = storeCartItemToRemove
      let newLineItems = [...order.lineItems]

      // if quantity > 1 and product is available, reduce quantity by 1, otherwise, remove the product from the cart
      if (quantity > 1 && !isUnavailable) {
        const itemToRemove = newLineItems.find(i =>
          i.sku === '83333333' ? i.sku === skuToRemove && i.unitPrice === unitPrice : i.sku === skuToRemove,
        )
        itemToRemove.quantity -= 1
      } else {
        newLineItems = newLineItems.filter(i =>
          i.sku === '83333333' ? i.sku !== skuToRemove && i.unitPrice !== unitPrice : i.sku !== skuToRemove,
        )
      }
      // Remove item from warranty tracking in local storage
      const warrantyJSON = localStorage.getItem('storeCartEscItems')
      if (warrantyJSON) {
        const warrantyArray = JSON.parse(warrantyJSON)
        const removedItemWarrantyArray = warrantyArray.filter(i => i.sku !== skuToRemove)
        localStorage.setItem('storeCartEscItems', removedItemWarrantyArray)
      }

      try {
        const location = getCurrentLocation()
        const newOrder = await updateLineItems({
          orderId: order.orderId,
          lineItems: newLineItems,
          region: location.region,
          zone: parseInt(location.price_zone),
          distribution_index: parseInt(location.distribution_index),
        })
        if (newOrder && newOrder.storeCart) {
          dispatch(setOrder(newOrder))
          removeStoreCartEvent(newOrder.storeCart, storeCartItemToRemove)
          if (isUnavailable) {
            setSkusNotAvailable(current => current.filter(i => i.sku !== skuToRemove))
          }
        }
      } catch (error) {
        sentryLogger({
          configureScope: {
            type: setExtra,
            level: levels.error,
            lineItems: newLineItems,
            action: 'remove',
            sku: skuToRemove,
            product: storeCartItemToRemove,
          },
        })
        throw new Error('Could not remove The Store Cart Item')
      }
    }
  }

  const toggleStoreCartProductWarranty = async lineItem => {
    const { isUnavailable } = lineItem
    const hasChildren = lineItem?.childItems?.length > 0

    // Save Item locally
    try {
      const storeCartEscItems = localStorage.getItem('storeCartEscItems')
      const escItemsArray = storeCartEscItems ? JSON.parse(storeCartEscItems) : []
      const escItem = { sku: lineItem?.sku, orderId: order?.orderId }
      const storageContainsItem = escItemsArray.find(i => i.sku === lineItem.sku && i.orderId === order?.orderId)
      if (!storageContainsItem) escItemsArray.push(escItem)
      const stringifiedEscItemsArray = JSON.stringify(escItemsArray)
      localStorage.setItem('storeCartEscItems', stringifiedEscItemsArray)
    } catch (err) {
      console.error(err)
    }

    // Toggle esc for lineItem and child items
    if (storeCartHasProducts) {
      const newLineItems = [...order.lineItems]
      const itemToToggle = newLineItems.find(i => i.sku === lineItem?.sku)
      itemToToggle.warrantyEnabled = !itemToToggle.warrantyEnabled
      if (hasChildren) {
        // eslint-disable-next-line
        itemToToggle.childItems.forEach(item => (item.warrantyEnabled = itemToToggle.warrantyEnabled))
      }

      try {
        const location = getCurrentLocation()
        const newOrder = await updateLineItems({
          orderId: order.orderId,
          lineItems: newLineItems,
          region: location.region,
          zone: parseInt(location.price_zone),
          distribution_index: parseInt(location.distribution_index),
        })

        if (newOrder && newOrder.storeCart) {
          dispatch(setOrder(newOrder))
          if (isUnavailable) {
            setSkusNotAvailable(current => current.filter(i => i.sku !== lineItem.sku))
          }
        }
      } catch (error) {
        sentryLogger({
          configureScope: {
            type: setExtra,
            level: levels.error,
            lineItems: newLineItems,
            action: 'removeWarranty',
            sku: lineItem.sku,
            product: lineItem,
          },
        })
        throw new Error(`Could not remove the warrranty on ${lineItem.title}`)
      }
    }
  }

  const renderCartProducts = () =>
    cart.cartItems.map((item, index) => {
      // adds stock message to any child products of a cart product
      const items_in_room =
        item?.product?.items_in_room?.map?.(itm => ({
          ...itm,
          stockMessage: availabilityStockMessage({
            availableOn: false,
            product: item.product,
            rtg_location,
          }),
        })) || []

      const cartItem = { ...item, product: { ...item.product, stockMessage: '', items_in_room } }
      //  Check if order.promotions.lineItems contains a matching sku with bonusItemSavings property and discount is applied?
      const promoInfo =
        order?.promotions?.lineItems?.find(
          promo => promo.sku === cartItem.sku && promo.bonusItemSavings && promo.promotionStatus === 'APPLIED',
        ) || null

      const additionalOptionLineItem = cartItem.additionalOption
        ? order?.lineItems?.find(lineItem => lineItem.sku === cartItem?.sku && !!lineItem.additionalOption)
        : null

      return (
        <div key={cartItem?.uid || cartItem?.sku} className="online-cart-wrapper">
          {index === 0 && <LargeHeader isMobile={isMobile} text="Online Cart" />}
          {index === 0 && splitDeliveryElig && skusNotAvailable?.length === 0 && (
            <SplitDeliveryTextWrapper mb={2} mt={{ xs: 2, md: 0 }}>
              <SplitDeliveryText>{splitCheckVerbiage}</SplitDeliveryText>
            </SplitDeliveryTextWrapper>
          )}
          <CartProduct
            bonusBuyDiscount={promoInfo?.bonusItemSavings}
            cartItem={cartItem}
            index={index}
            isMobile={isMobile}
            productCount={cart?.cartItems?.length}
            region={rtg_location.region}
            unavailableItem={skusNotAvailable?.filter?.(prod => prod.sku === cartItem.sku)?.[0]}
            warrantyEnabled={cartItem.warrantyEnabled || cartItem.isWarrantyEnabled}
            lineItem={additionalOptionLineItem}
            showFinanceMessage={isShowFinanceMessageFeatureOn}
          />
        </div>
      )
    })

  const onCombineStoreAndOnlineCarts = () => {
    sessionStorage.setItem(userAcknowledgedToCombineStoreAndOnlineCarts, 'yes')
    setRefreshComponent(!refreshComponent)
  }

  const onClearOnlineCart = () => {
    dispatch(clearCart())
    clearCheckoutState(false, true, false, true)
  }

  const unavailableSkus = skusNotAvailable?.map(i => i.sku) ?? []
  const onlineCartSkus = onlineCartHasProducts ? cart.cartItems.map(i => i.sku) : []
  const showRemovalAll = onlineCartSkus.some(s => unavailableSkus.includes(s))

  return (
    <>
      {showEquickBanner && <EquickBanner handleClose={() => setShowEquickBanner(false)} {...eQuickProps} />}

      <div className="cart-page">
        <div className={classNames('grid-x', { 'grid-margin-x': isMobile })}>
          <div
            className={classNames('cart-product-list-container small-12 medium-12 large-9 grid-x grid-padding-y', {
              cell: isMobile,
            })}
          >
            {/* Both Carts Are Empty Component */}
            {!storeCartHasProducts && !onlineCartHasProducts && !storeCartIsLoading && <CartEmpty />}
            {/* Store Cart Is Loading */}
            {showStoreCartLoading && (
              <CartAndCheckoutSpinnerWrapper>
                <CircularProgress />
                <LoadingMessage>Loading Your Cart</LoadingMessage>
              </CartAndCheckoutSpinnerWrapper>
            )}
            {/* Store Cart Component */}
            {/* Redundant storecartloading check since storeCartHasProducts memo will not run again if the cart has not changed
            but storeCartIsLoading is modified by cartUpdate, which always will */}
            {!storeCartIsLoading && storeCartHasProducts && (
              <StoreCart
                isMobile={isMobile}
                onlineCartHasProducts={onlineCartHasProducts}
                orderId={order?.orderId}
                removeStoreCartProduct={removeStoreCartProduct}
                setStoreCartIsLoading={setStoreCartIsLoading}
                splitDeliveryMessage={splitDeliveryElig && skusNotAvailable?.length === 0 ? splitCheckVerbiage : null}
                storeCartImages={storeCartImages}
                storeCartItems={storeCartLineItems}
                storeInfo={order?.storeInfo}
                toggleStoreCartProductWarranty={toggleStoreCartProductWarranty}
                unavailableItems={skusNotAvailable?.length ? skusNotAvailable.map(x => x.sku) : []}
              />
            )}

            {/* Online Cart Component */}
            {onlineCartHasProducts && renderCartProducts()}

            {/* Promotions Component */}
            {(onlineCartHasProducts || storeCartHasProducts) && (
              <>
                <PromotionContentGroup targetSkus={promoTargetSkus} isCart />
                <div
                  className="cart-bottom-checkout cell small-12 grid-x"
                  style={
                    isMobile ? null : { marginBottom: !storeCartHasProducts || onlineCartHasProducts ? '15px' : 0 }
                  }
                >
                  {isMobile ? (
                    <PaymentMethods
                      cart={cart}
                      showWellsFargoLogo={order?.altFinancing ?? false}
                      uniqueNameForId="mobilePayMethods"
                    />
                  ) : (
                    <Grid container>
                      <Grid item xs={8}>
                        <PaymentMethods
                          cart={cart}
                          showWellsFargoLogo={order?.altFinancing ?? false}
                          uniqueNameForId="desktopPayMethods"
                          width="90%"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CheckoutSticky
                          cart={cart}
                          discount={discount}
                          financeAmount={totalFinanceAmount}
                          financeNumOfMonths={financeNumOfMonths}
                          onlyButtons
                          order={order}
                          productsAvailable={productsAvailable}
                          removeUnavailableItems={
                            showRemovalAll ? () => removeUnavailableItems(setSkusNotAvailable, skusNotAvailable) : null
                          }
                          showPayPal={showPayPal}
                          storeCartIsLoading={storeCartIsLoading}
                          storeCartLineItems={storeCartLineItems}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </>
            )}
            {!isMobile && storeCartHasProducts && !onlineCartHasProducts && (
              <StoreCartParagraph isMobile={isMobile} storeName={order?.storeInfo?.storeName} />
            )}

            {/* Credit Card Banner Component (for mobile only; displays in CheckoutSticky sidebar for desktop */}
            {isMobile && (onlineCartHasProducts || storeCartHasProducts) && (
              <>
                {isPrequalified && <EquickBanner isSidebar {...eQuickProps} />}
                <MobileCartCreditCardBannerWrapper>
                  {!isPrequalified && <CartCreditCardBanner />}
                  <SalesContact />
                </MobileCartCreditCardBannerWrapper>
              </>
            )}

            {/* You May Also Like */}
            {carouselSlides?.length > 0 && (
              <PRODUCT_CAROUSEL
                id="you-may-also-like__cart"
                title="You May Also Like"
                trigger_gtm={addToDataLayer_nextgen}
                price_zone={`${rtg_location.region}_${rtg_location.price_zone}`}
                slides={carouselSlides}
                addToCart={addToCart}
                cartItems={cart?.cartItems?.map(product => ({
                  sku: product.sku,
                  price: product.price,
                  quantity: product.quantity,
                }))}
                cartTotal={order.total}
              />
            )}

            {/* {shouldShowFinanceText && isShowFinanceMessageFeatureOn && (
              <p className="financing-disclaimer cell small-12">*when paying with a Rooms To Go credit card</p>
            )} */}

            {/*  Added to stop from passing classes to other divs */}
            <FullWidthDiv>
              <FullWidthDiv>
                <CondensedContact />
              </FullWidthDiv>
            </FullWidthDiv>
          </div>

          {/* Your Order Checkout Component */}
          <div className="checkout-container cell large-2 grid-margin-y">
            {(onlineCartHasProducts || storeCartHasProducts) && (
              <CheckoutSticky
                cart={cart}
                discount={discount}
                eQuickProps={{ isPrequalified, ...eQuickProps }}
                financeAmount={totalFinanceAmount}
                financeNumOfMonths={financeNumOfMonths}
                isMobile={isMobile}
                order={order}
                productsAvailable={productsAvailable}
                removeUnavailableItems={
                  showRemovalAll ? () => removeUnavailableItems(setSkusNotAvailable, skusNotAvailable) : null
                }
                rightSticky
                showPayPal={showPayPal}
                storeCartIsLoading={storeCartIsLoading}
                storeCartLineItems={storeCartLineItems}
              />
            )}
          </div>
        </div>
        {showCombineCartsModal && (
          <ViewMyStoreCartModal
            associateName={order?.storeCart?.salesPersonInfo ?? 'the Rooms To Go Sales Associate'}
            storeCartItems={order?.storeCart?.lineItems?.length}
            onlineCartItems={cart?.cartItems?.length}
            open={showCombineCartsModal}
            onCombineStoreAndOnlineCarts={onCombineStoreAndOnlineCarts}
            onClearOnlineCart={onClearOnlineCart}
          />
        )}
      </div>
    </>
  )
}

AllCartsWrapper.propTypes = {
  cart: PropTypes.object,
  discount: PropTypes.number,
  isMobile: PropTypes.bool,
  order: PropTypes.object,
  promoTargetSkus: PropTypes.array,
  rtg_location: PropTypes.object,
  setSkusNotAvailable: PropTypes.func,
  showPayPal: PropTypes.bool,
  skusNotAvailable: PropTypes.array,
  storeCartIsLoading: PropTypes.bool,
  setStoreCartIsLoading: PropTypes.func,
}

export default AllCartsWrapper
