import React from 'react'
import { bool, object, string } from 'prop-types'
import { Stack } from '@mui/material'
import PaymentSvg from '@shared/svgs/paymentSvgComp'

const cards = [
  'discover',
  'mastercard',
  'visa',
  'amex',
  'rtg',
  'wfg',
  'paypal',
  'acima',
  'klarna',
  'affirm',
  'bitpay',
  'genesis',
]

const PaymentMethods = ({ cart, showWellsFargoLogo = false, uniqueNameForId, width }) => (
  <Stack sx={{ padding: '0 1rem', width: '100%' }}>
    <h4 className="bold">We Accept</h4>
    <PaymentSvg
      cards={showWellsFargoLogo ? cards : cards.filter(c => c !== 'wfg')}
      cart={cart}
      uniqueNameForId={uniqueNameForId}
      width={width}
    />
  </Stack>
)

PaymentMethods.propTypes = {
  cart: object,
  showWellsFargoLogo: bool,
  uniqueNameForId: string,
  width: string,
}

export default PaymentMethods
