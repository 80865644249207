import { styled } from '@mui/material'

export const ProductListDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  transition: ' all 0.5s ease-in-out',
  '& > div': {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  [theme.breakpoints.down('md')]: {
    padding: '22px 16px',
  },
}))

export const ActiveAddonsDiv = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '100px 3fr 1fr',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    girdTemplateColumns: '1fr',
    flexDirection: 'column',
    marginLeft: 0,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.75em',
  },
}))

export const InfoDiv = styled('div')(({ theme }) => ({
  display: 'grid',
  marginLeft: '4em',
  marginBottom: '1em',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    margin: 0,
  },
}))

export const PriceDiv = styled('span')(({ theme }) => ({
  minWidth: '5em',
  [theme.breakpoints.down('md')]: {
    minWidth: '5.3em',
  },
}))

export const Plan = styled('span')(({ theme }) => ({
  fontSize: '13px',
  [theme.breakpoints.down('md')]: {
    whiteSpace: 'nowrap',
  },
}))

export const WarrantyContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: 1,
}))

export const Quantity = styled('span')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: '8px',
  fontWeight: 600,
}))

export const Sku = styled('span')(({ theme }) => ({
  fontSize: '0.85em',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '.9em',
    marginTop: '.5em',
    fontWeight: 300,
  },
}))

export const Title = styled('span')(({ theme }) => ({
  fontWeight: 600,
  textTransform: 'uppercase',
  fontSize: `16px`,
  [theme.breakpoints.down]: {
    marginTop: '15px',
  },
}))

export const Img = styled('img')(({ theme }) => ({
  maxWidth: '100px',
  height: '60px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    maxWidth: '150px',
    height: '75px',
  },
}))

export const View = styled('div')(({ theme, currentState }) => ({
  display: 'none',
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
  padding: currentState ? '25px 0 14px' : '0 0 14px',
  margin: '0 auto',
  '& > svg ': {
    display: 'inline-block',
    fill: theme.palette.primary.main,
    margin: '0 0 0 4px',
    height: '12px',
    transform: currentState ? 'rotate(0deg)' : 'rotate(180deg)',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))
