import { styled } from '@mui/material'

export const ProductWrapper = styled('div')(() => ({
  display: 'flex',
  backgroundColor: '#ffffff',
  marginTop: '1.5rem',
  '&:first-of-type': {
    marginTop: 0,
  },
}))

export const ProductList = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '3fr 1fr auto',
  alignItems: 'center',
  width: '100%',
  padding: '1rem',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    padding: 0,
  },
}))

export const Count = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '25px',
  backgroundColor: `${theme.palette.primary.dark}`,
  color: `${theme.palette.common.white}`,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const Small = styled('span')(({ theme }) => ({
  fontSize: '.75rem',
  fontWeight: 400,
  [theme.breakpoints.down('md')]: {
    marginLeft: '5px',
  },
}))

export const Remove = styled('span')(({ theme }) => ({
  fontSize: '.75rem',
  lineHeight: 1,
  textDecoration: 'underline',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

export const Price = styled('div')(({ theme }) => ({
  color: `'#333333'`,
  fontSize: '16px',
  fontWeight: 600,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    borderTop: `1px solid #DEDEDE`,
    padding: '14px 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const CloseIcon = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'none',

  top: '2px',
  right: '3px',
  '&&& > button': {
    backgroundColor: '#DEDEDE',
    borderRadius: '50%',
    '> img': {
      marginTop: 0,
    },
  },

  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

export const StyledInput = styled('input')(({ theme }) => ({
  marginRight: '8px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '8px',
  },
}))
